import { useEffect } from "react";

const BotPenguinWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "messenger-widget-b";
    script.src = "https://cdn.botpenguin.com/website-bot.js";
    script.defer = true;

    // Add the bot ID as inner text
    script.textContent = "67a08c0dabf27e04ad51ffae,67a08bee91287a05c7e9def8";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup to avoid duplicate scripts
    };
  }, []);

  return null; // This component does not render anything
};

export default BotPenguinWidget;
