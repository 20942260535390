import './App.css';
import Layout from './container/layout/layout';
import { Routes, Route, BrowserRouter as Router, } from 'react-router-dom'
import Main from './pages/main'
import PartnerWithUs from './pages/partner-with-us/partner-with-us';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './pages/404-page';
import Blogs from './pages/blogs';
import BlogDetails from './pages/blogs/blog-details';
import Enquiry from './pages/enquiry';
import BotPenguinWidget from './penguin-bot';

function App() {
  return (
    <>
  <BotPenguinWidget />
      <Router >
        <Routes>
          <Route element={<Layout />} >
            <Route path="/" element={<Main />} />
            <Route path="/partner-with-us" element={<PartnerWithUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:id" element={<BlogDetails />} />
            <Route path="/enquiry" element={<Enquiry />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>

      <ToastContainer
        limit={1}
        autoClose={3000}
        transition={Zoom}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        newestOnTop
      />
    </>
  );
}

export default App;
